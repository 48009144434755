export default function lotteryCodeLength (code) {
  const cz_obj = {
    'xyft': 10,
    'donggansaiche': 10,
    '168xyft': 10,
    'jisupk10': 10,
    'azxy10': 10,
    'jisupk10168': 10,
    'azxy10168': 10,
    'sbspeedy10': 10,
    'sgairship': 10,

    'azxy8': 8,
    'jisukl10': 8,
    'azxy8168': 8,
    'jisukl10168': 8,
    'gdklsf': 8,

    'donggan61': 7,
    'amlhc':  7,
    'twlhc': 7,
    'hk6':  7,
    'happy61': 7,

    'dongganshengxiao': 6,

    'azxy5':  5,
    'azxy5168':  5,
    'txffc':  5,
    'jisussc':  5,
    'jisussc168':  5,
    'dongganwuxing':  5,
    'sg5d': 5,

    'dongganpuke': 4,

    'donggansanbao': 3,
    'speedy3': 3,
    'sgquick3': 3,

    'pcdd': 3
  }
  return cz_obj[code] || 10
}
