<template>
  <div class="lottery-omit">
    <div class="header">
      <div class="title-box">
        <p class="title">遗漏统计</p>
        <intro-default cont="统计不同条件下号码的出现次数及遗漏（未出现）次数。出现‘!’表示期数过少，无法统计"></intro-default>
      </div>
      <div class="search-box">
        <select-user type="0" @change="page_change"></select-user>
      </div>
    </div>
    <div class="lz_cont">
      <ul class="switch" v-if="types.length != 1">
        <li :class="{ active: indexForSelectType === i }" v-for="(option, i) in types" :key="option.value"
          @click="selectedTypeAction(i)">{{ option.label }}</li>
        <li class="types_empty"></li>
      </ul>
      <div class="ranks_box" v-if="hasLocation">
        <span v-if="types.length != 1">选择查看:</span>
        <ul :class="types.length === 1 ? 'switch' : 'ranks'">
          <li :class="{ active: indexForSelectLocation === i }" v-for="(name, i) in locations" :key="name"
            @click="selectedLocationAction(i)">{{ name }}</li>
        </ul>
      </div>
      <div v-if="locations.length === 0" style="margin-bottom: 26px"></div>
      <div class="table">
        <el-table :data="data" border style="width: 100%" highlight-current-row
          header-cell-class-name="table-header-cell" cell-class-name="table-cell" row-class-name="table-row"
          size="small" row-key="id" id="table">
          <el-table-column :label="types[indexForSelectType].label" align="center" prop="number">
            <template slot-scope="scope">
              <span v-if="is_qiu(scope.row.number)" class="number_box">
                <number class="number"
                  :value="code === 'dongganshengxiao' ? ['鼠', '牛', '虎', '兔', '龙', '蛇', '马', '羊', '猴', '鸡', '狗', '猪'].indexOf(scope.row.number) + 1 : scope.row.number"
                  :lottery-code="code"></number>
              </span>
              <span v-else style="font-weight: bold;">{{ scope.row.number }}</span>
            </template>
          </el-table-column>
          <el-table-column label="开出几率" align="center" prop="chance">
            <template slot-scope="scope">
              <span v-if="scope.row.chance == '!'" style="color: #FA3E3E;">!</span>
              <span v-else>{{ scope.row.chance }}</span>
            </template>
          </el-table-column>
          <el-table-column label="当前遗漏" align="center" prop="now_missing">
            <template slot-scope="scope">
              <span v-if="scope.row.now_missing == '!'" style="color: #FA3E3E;">!</span>
              <span v-else>{{ scope.row.now_missing }}</span>
            </template>
          </el-table-column>
          <el-table-column label="平均遗漏" align="center" prop="average_missing">
            <template slot-scope="scope">
              <span v-if="scope.row.average_missing == '!'" style="color: #FA3E3E;">!</span>
              <span v-else>{{ scope.row.average_missing }}</span>
            </template>
          </el-table-column>
          <el-table-column label="最大遗漏" align="center" prop="max_missing">
            <template slot-scope="scope">
              <span v-if="scope.row.max_missing == '!'" style="color: #FA3E3E;">!</span>
              <span v-else>{{ scope.row.max_missing }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Loading } from 'element-ui'
import lotteryCodeLength from '@/src/config/lottery-code-length'
import Number from '@components/pc/Number'

export default {
  name: 'LotteryOmit',
  props: {
    code: String
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Number.name]: Number
  },
  data() {
    return {
      per_page: 50,
      data: [],
      loading: null,
      indexForSelectLocation: 0,
      indexForSelectType: 0
    }
  },
  computed: {
    opencodeLength() {
      return lotteryCodeLength(this.code)
    },
    hasLocation() {
      return this.locations.length > 0
    },
    locations() {
      if (this.opencodeLength === 8) {
        let arr = ['第一球', '第二球', '第三球', '第四球', '第五球', '第六球', '第七球', '第八球']
        if (this.valueOfSelectType === 'number') {
          arr = arr.concat(['任选一'])
        }
        return arr
      } else if (this.opencodeLength === 6) {
        return ['第一球', '第二球', '第三球', '第四球', '第五球', '第六球', '任选一']
      } else if (this.opencodeLength === 5) {
        return ['第一球', '第二球', '第三球', '第四球', '第五球', '任选一']
      } else if (this.opencodeLength === 4) {
        return ['第一球', '第二球', '第三球', '第四球', '任选一']
      } else if (this.opencodeLength === 3) {
        if (this.code === 'pcdd') {
          let arr = []
          if (this.valueOfSelectType === 'number') {
            arr = arr.concat(['第一名', '第二名', '第三名', '任选一', '总和'])
          }
          return arr
        } else {
          return ['三军', '总和', '二同号', '三同号', '三不同']
        }
      } else if (this.opencodeLength === 7) {
        let arr = ['号码', '生肖', '尾数', '波色']
        if (this.valueOfSelectType === 7) {
          arr = ['一中一', '七码生肖', '七码尾数']
        }
        return arr
      } else {
        let arr = ['冠军', '亚军', '第三名', '第四名', '第五名']
        if (this.valueOfSelectType !== 'loong_tiger') {
          arr = arr.concat(['第六名', '第七名', '第八名', '第九名', '第十名'])
        }
        if (this.valueOfSelectType === 'number') {
          arr = arr.concat(['冠亚和', '三四和', '五六和', '七八和', '九十和'])
        }
        return arr
      }
    },
    types() {
      switch (this.opencodeLength) {
        case 10:
          return [
            {
              label: '号码',
              value: 'number'
            },
            // {
            //   label: '单双',
            //   value: 'single_double'
            // },
            // {
            //   label: '大小',
            //   value: 'big_small'
            // },
            // {
            //   label: '龙虎',
            //   value: 'loong_tiger'
            // }
          ]
        case 8:
          return [
            {
              label: '号码',
              value: 'number'
            },
            // {
            //   label: '方位',
            //   value: 'around'
            // },
            // {
            //   label: '中发白',
            //   value: 'centre_hair_white'
            // }
          ]
        case 7:
          return [
            {
              label: '正一',
              value: 0
            },
            {
              label: '正二',
              value: 1
            },
            {
              label: '正三',
              value: 2
            },
            {
              label: '正四',
              value: 3
            },
            {
              label: '正五',
              value: 4
            },
            {
              label: '正六',
              value: 5
            },
            {
              label: '特码',
              value: 6
            },
            {
              label: '七码',
              value: 7
            }
          ]
        case 6:
          return [
            {
              label: '生肖',
              value: 'zodiac'
            },
            // {
            //   label: '五行',
            //   value: 'five_shapes'
            // },
            // {
            //   label: '八卦',
            //   value: 'eight_trigrams'
            // }
          ]
        case 5:
          return [
            {
              label: '号码',
              value: 'number'
            }
          ]
        case 4:
          return [
            {
              label: '号码',
              value: 'number'
            },
            // {
            //   label: '牌形',
            //   value: 'shape'
            // }
          ]
        case 3:
          if (this.code === 'pcdd') {
            return [
              {
                label: '号码',
                value: 'number'
              },
              // {
              //   label: '总和',
              //   value: 'sum'
              // }
            ]
          }
          return [
            {
              label: '三军',
              value: 0
            }
          ]
        default:
          return []
      }
    },
    valueOfSelectType() {
      return this.types[this.indexForSelectType].value
    }
  },
  watch: {
    code: function (val, oldVal) {
      this.indexForSelectLocation = 0,
        this.indexForSelectType = 0
      this.per_page = 50
      this.requestGetHistory()
    }
  },
  mounted() {
    this.requestGetHistory()
  },
  methods: {
    page_change(value) {
      this.per_page = value
      this.requestGetHistory()
    },
    is_qiu(number) {
      if (this.opencodeLength === 10) {
        if (this.indexForSelectType === 0 && this.indexForSelectLocation < 10) {
          return true
        }
      } else if (this.opencodeLength === 8) {
        if (this.indexForSelectType === 0) {
          return true
        }
      } else if (this.opencodeLength === 7) {
        if (this.indexForSelectLocation === 0) {
          return true
        }
      } else if (this.opencodeLength === 6) {
        const arr = ['鼠', '牛', '虎', '兔', '龙', '蛇', '马', '羊', '猴', '鸡', '狗', '猪']
        return arr.indexOf(number) !== -1
      } else if (this.opencodeLength === 5) {
        return true
      } else if (this.opencodeLength === 4) {
        return false
      } else if (this.opencodeLength === 3) {
        if (this.code === 'pcdd') {
          if (this.indexForSelectLocation !== 4) {
            return true
          }
        } else {
          if (this.indexForSelectLocation === 0) {
            return true
          }
        }
      }
      return false
    },
    refresh() {
      this.requestGetHistory()
    },
    selectedTypeAction(index) {
      this.indexForSelectType = index
      this.indexForSelectLocation = 0
      this.requestGetHistory()
    },
    selectedLocationAction(index) {
      this.indexForSelectLocation = index
      this.requestGetHistory()
    },
    requestGetHistory() {
      this.data = []
      this.showTableLoading()
      let t1 = this.valueOfSelectType
      let t2 = this.indexForSelectLocation
      if (this.opencodeLength === 7) {
        t2 = this.valueOfSelectType
        t1 = this.indexForSelectLocation
        const arr = ['number', 'zodiac', 'tail', 'bose']
        t1 = arr[t1]
      }
      if (this.code === 'donggansanbao' || this.code === 'speedy3' || this.code === 'sgquick3') {
        t2 = this.valueOfSelectType
        t1 = this.indexForSelectLocation
        const arr = ['number', 'sum', 'two_identical', 'two_different', 'three_identical', 'three_different']
        t1 = arr[t1]
      }
      this.$api.missing(this.code, t1, t2 + 1, this.per_page).then((res) => {
        this.data = res.data
        this.hideTableLoading()
      }).catch((err) => {
        this.hideTableLoading()
        this.$message.error(err)
      })
    },
    showTableLoading() {
      if (this.loading) return
      this.loading = Loading.service({
        target: '#table',
        fullscreen: false,
        background: 'rgba(0, 0, 0, 0)'
      })
    },
    hideTableLoading() {
      this.$nextTick(() => {
        this.loading.close()
        this.loading = null
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@scss/pc/variables';

.lottery-omit {
  >.header {
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    border-bottom: thin $color-border solid;

    >.title-box {
      display: flex;
      align-items: center;
      height: 49px;

      >.title {
        font-size: 20px;
        font-weight: 400;
        color: #222;
      }
    }

    >.search-box {
      display: flex;
      align-items: center;

      >.title {
        margin-right: 15px;
        font-size: 14px;
        color: #222;
      }

      >.icon-search {
        margin-left: 10px;
        width: 48px;
        height: 24px;
        display: block;
      }
    }
  }

  .lz_cont {
    padding: 20px;
    padding-top: 30px;
    padding-bottom: 0;
    box-sizing: border-box;
  }

  .switch {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    margin-bottom: 26px;

    >li {
      margin-right: 10px;
      cursor: pointer;
      list-style: none;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #222;
      height: 26px;
      border-radius: 50px;

      &.active {
        color: #FA3E3E;
        background-color: #FEEBEB;
      }
    }
  }

  .ranks_box {
    display: flex;
    align-items: center;

    >span {
      margin-right: 16px;
      margin-bottom: 26px;
      padding-left: 10px;
      font-size: 12px;
      font-weight: bold;
    }
  }

  .ranks {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 26px 0;
    margin-top: 0;

    >li {
      cursor: pointer;
      padding: 0 8px;
      min-width: 70px;
      height: 24px;
      border-radius: 50px;
      line-height: 24px;
      font-size: 12px;
      text-align: center;
      color: #939395;

      &.active {
        border: 1px solid #FA3E3E;
        color: #FA3E3E;
      }

      &+li {
        margin-left: 10px;
      }
    }
  }

}

.number_box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<style>
.lottery-omit .table {
  margin-top: 20px;
}

.lottery-omit .table .table-header-cell {
  background-color: #f9f9f9;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  padding: 0;
  height: 40px;
}

.lottery-omit .table .table-header-cell>.cell {
  padding: 0;
}

.lottery-omit .table .table-cell {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  padding: 0;
  height: 40px;
}

.lottery-omit .table .table-cell>.cell {
  padding: 0;
}

.lottery-omit .table .table-row:hover>td,
.lottery-omit .table .table-row .current-row>td {
  background-color: rgba(255, 204, 170, 0.4);
}
</style>
